<template>
  <div class="h-screen w-full flex items-center justify-center">
    <div
      class="max-w-[480px] mx-auto h-auto p-8 shadow-xl border border-gray-200 rounded-md bg-white"
    >
      <form
        @submit.prevent="handleSubmit"
        class="flex flex-col gap-2 h-full relative"
      >
        <div
          class="absolute top-4 left-0 flex gap-2 items-center cursor-pointer"
          @click="backToPlan"
        >
          <img
            src="@/assets/right-arrow.png"
            alt=""
            class="h-5 w-5 object-contain rotate-180"
          />
          <button class="text-sm">Back</button>
        </div>
        <img
          src="@/assets/Logo.svg"
          alt="LIVEYfy LOGO"
          class="object-contain h-[40px]"
        />
        <!-- <h1 class="py-2">LIVEY TECHNOLOGIES LLC</h1> -->
        <div class="flex flex-col gap-4 py-4">
          <p class="flex justify-start">
            <span class="w-[75%] flex justify-start items-center gap-2">
              <img
                src="@/assets/bag.png"
                alt=""
                class="w-4 h-4 object-contain"
              />
              Actual Prize
            </span>
            <span class="w-[20%] flex justify-end">
              ${{ schemePlan[0]?.marked_price }}</span
            >
          </p>
          <p class="flex justify-start">
            <span class="w-[75%] flex justify-start items-center gap-2">
              <img
                src="@/assets/tag.png"
                alt=""
                class="w-4 h-4 object-contain"
              />
              Discount
              <span class="text-green-700"
                >({{ schemePlan[0]?.discount_percentage }})%</span
              >
            </span>
            <span class="w-[20%] flex justify-end text-green-700">
              ${{
                (
                  (schemePlan[0]?.marked_price / 100) *
                  schemePlan[0]?.discount_percentage
                ).toFixed(2)
              }}</span
            >
          </p>

          <hr />

          <p class="flex justify-start pb-2">
            <span class="w-[75%] flex justify-start items-center gap-2">
              <img
                src="@/assets/credit-card.png"
                alt=""
                class="w-4 h-4 object-contain"
              />
              To pay
            </span>
            <span class="w-[20%] flex justify-end font-semibold">
              ${{ schemePlan[0]?.selling_price }}</span
            >
          </p>
        </div>
        <div class="flex gap-4">
          <button
            class="border rounded-lg bg-gray-100"
            @click="checkout('amazon_pay')"
            title="amazon_pay"
          >
            <img
              src="@/assets/amazon-pay.png"
              alt=""
              class="w-[200px] h-[60px] object-contain"
            />
          </button>
          <button
            class="border rounded-lg bg-gray-100"
            @click="checkout('card')"
            title="card_pay"
          >
            <img
              src="@/assets/credit-card-color.png"
              alt=""
              class="w-[200px] h-[60px] object-contain"
            />
          </button>

          <!-- <button class="border px-2 py-1" @click="checkout('us_bank_account')">
            US Bank Account
          </button> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      stripe: null,
      userDetails: { name: "", email: "" },
      productDetails: {
        name: "LIVEYfy",
        description: "Organization Subscription Plan",
      },
      schemePlan: [],
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  created() {
    this.handleCardChange();
    this.userDetails.name = this.getUser.display_name;
    this.userDetails.email = this.getUser.email;
  },
  async mounted() {
    this.stripe = await loadStripe(
      `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`
    );
  },
  methods: {
    backToPlan() {
      this.$router.push({ name: "SubscriptionView" });
    },
    async handleCardChange() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-subscriptions/?title=${this.$route.params.plan_type}`
        )
        .then((response) => {
          this.schemePlan = response.data.results;
          if (this.schemePlan.length == 0) {
            this.$router.push({ name: "PageNotFound" });
          }
        })
        .catch((error) => {
          console.log(error, "hello");
        });
    },
    async checkout(paymentMethod) {
      const response = await fetch(
        `${process.env.VUE_APP_API}/api/create-checkout-session/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_method: paymentMethod,
            product_details: this.productDetails,
            quantity: this.quantity,
            user_details: this.userDetails,
            user_id: this.getUser.id,
            subscription_id: this.schemePlan[0]?.id,
            cancel_url: this.$route.params.plan_type,
          }),
        }
      );
      const session = await response.json();
      if (session.error) {
        alert(session.error);
      } else {
        console.log(session, "session");
        await this.stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }
    },
  },
};
</script>
