<template>
  <div class="flex">
    <SideNav />

    <div class="flex-1 bg-[#F1F8FE] w-full">
      <TopBar />
      <div class="h-[calc(100vh-60px)] overflow-y-auto">
        <div class="px-2 w-[calc(100vw-220px)]">
          <div class="flex justify-between items-center py-2">
            <h1 class="text-start text-2xl">User Details</h1>
            <div class="flex">
              <select
                @change="getUserInfo(this.current_page)"
                v-model="license_status"
                name=""
                id=""
                class="h-10 px-4 py-1 bg-white rounded-md"
              >
                <option value="" disabled selected class="text-gray-700">
                  Filter by
                </option>
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="expired">Expired</option>
              </select>
            </div>
          </div>
          <table
            class="text-left rounded-md overflow-auto w-[calc(100vw-220px)]"
          >
            <thead class="p-5 text-white h-14">
              <tr class="bg-[#2a84ef] h-10 px-2">
                <th class="p-2 text-sm roboto-light font-medium text-center">
                  No
                </th>
                <th class="p-2 text-sm roboto-light font-medium">Name</th>
                <th class="p-2 text-sm roboto-light font-medium">Email</th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Phone number
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Access Type
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Organization Name
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Joined Date
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Expiry Date
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Active Status
                </th>
              </tr>
            </thead>
            <tbody class="w-full">
              <template v-if="user_info">
                <tr
                  class="text-left py-2 border rounded-lg cursor-pointer"
                  @click="(popUp = true), (select_user = user)"
                  v-for="(user, index) in user_info"
                  :key="user"
                  :class="(index + 1) % 2 == 0 ? 'bg-gray-200' : 'bg-white'"
                >
                  <td class="p-2 text-sm text-center">
                    {{ index + 1 + this.index_value }}
                  </td>
                  <td class="p-2 text-sm">
                    {{ user.first_name }}
                  </td>
                  <td class="p-2 text-sm">{{ user.email }}</td>
                  <td class="p-2 text-sm">{{ user.phone }}</td>
                  <td class="p-2 text-sm">{{ user.Permission_access }}</td>
                  <td class="p-2 text-sm">{{ user.organization }}</td>
                  <td class="p-2 text-sm">
                    {{ new Date(user.date_joined).toLocaleDateString("en-GB") }}
                  </td>
                  <td class="p-2 text-sm">
                    {{ new Date(user.expiry_date).toLocaleDateString("en-GB") }}
                  </td>
                  <td class="p-2 text-sm capitalize">
                    <span
                      class="px-3 py-1 rounded-md text-white"
                      :class="[
                        user.status == 'active' ? 'bg-green-700' : 'bg-red-700',
                      ]"
                    >
                      {{ user.status }}
                    </span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="text-center">
                  <td colspan="3">No data</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="mx-auto my-4">
          <PaginationViewVue
            @selectedPage="getUserInfo"
            :total_number_page="this.total_page"
            :count_per_page="this.count_per_page"
          />
        </div>
      </div>
    </div>
    <div
      v-if="popUp"
      class="fixed top-0 left-0 bg-black bg-opacity-50 h-full w-full flex items-center justify-center"
    >
      <div class="w-[720px] mx-auto bg-white p-10 relative rounded-md">
        <button
          class="absolute top-2 right-6 text-xl"
          @click="
            (popUp = false),
              (select_user = []),
              (update.new = ''),
              (update.confirm = ''),
              (is_reset_password = false)
          "
        >
          x
        </button>
        <div v-if="!is_reset_password" class="border p-5 rounded-md space-y-4">
          <div class="flex flex-col cursor-pointer">
            <label for="full_name" class="text-black font-medium"
              >Full Name</label
            >
            <input
              id="full_name"
              type="text"
              class="p-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              :value="select_user.first_name"
              required
              disabled
            />
          </div>
          <div class="flex flex-col cursor-pointer">
            <label for="email" class="text-black font-medium"> Email</label>
            <input
              id="email"
              type="text"
              class="p-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              :value="select_user.email"
              required
              disabled
            />
          </div>
          <div class="flex flex-col cursor-pointer">
            <label for="phone_number" class="text-black font-medium"
              >Phone Number</label
            >
            <input
              id="phone_number"
              type="text"
              class="p-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              :value="select_user.phone"
              required
              disabled
            />
          </div>
          <div class="flex flex-col cursor-pointer">
            <label for="organization" class="text-black font-medium"
              >Organization</label
            >
            <input
              id="organization"
              type="text"
              class="p-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              :value="
                select_user.organization ? select_user.organization : '---'
              "
              required
              disabled
            />
          </div>
          <button
            @click="is_reset_password = true"
            class="px-4 py-2 rounded-md bg-primary text-white"
            type="button"
          >
            Reset Password
          </button>
        </div>
        <form
          v-if="is_reset_password"
          @submit.prevent="updatePassword"
          class="flex flex-col gap-4"
        >
          <h1 class="text-2xl text-center">Reset Password</h1>
          <div class="flex flex-col gap-y-1">
            <label for="">New Password<sup class="text-red-500">*</sup></label>
            <div
              class="flex items-center outline-none border rounded-md p-2 gap-x-1"
            >
              <img src="@/assets/lock.svg" class="h-5 w-5" alt="" />
              <input
                type="password"
                pattern="^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                title="Password must be at least 8 characters long, include one uppercase letter, one numeric digit, and one special character."
                required
                v-model="update.new"
                placeholder="Enter new password"
                class="rounded-md bg-white placeholder:text-gray-400 outline-none w-full"
              />
            </div>
          </div>

          <div class="flex flex-col gap-y-1">
            <label for=""
              >Confirm Password<sup class="text-red-500">*</sup></label
            >
            <div
              class="flex items-center outline-none border rounded-md p-2 gap-x-1"
            >
              <img src="@/assets/lock.svg" class="h-5 w-5" alt="" />
              <input
                type="password"
                v-model="update.confirm"
                placeholder="Confirm the new password"
                class="rounded-md bg-white placeholder:text-gray-400 outline-none w-full"
              />
            </div>
          </div>
          <p
            v-if="this.message.text != ''"
            class="text-start text-xs md:text-md"
            :class="[
              this.message.status == 200 ? ' text-green-400' : ' text-red-400',
            ]"
          >
            {{ this.message.text }}
          </p>
          <div class="flex justify-center items-center gap-6">
            <button
              @click="
                (is_reset_password = false),
                  (update.new = ''),
                  (update.confirm = '')
              "
              class="rounded-md p-2 text-center shadow-lg hover:shadow-none w-20 bg-pink-500 text-white"
            >
              Close
            </button>
            <button
              type="submit"
              class="rounded-md p-2 text-center shadow-lg hover:shadow-none w-20 bg-[#2A84EF] text-white"
              :class="{ 'opacity-50 cursor-not-allowed': loading }"
              :disabled="loading"
            >
              <span v-if="!loading">Submit</span>
              <span v-else>Loading...</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SideNav from "@/components/SideNavAdmin.vue";
import TopBar from "@/components/TopBar.vue";
import PaginationViewVue from "@/components/PaginationPageView.vue";
export default {
  data() {
    return {
      user_info: [],
      count_per_page: 30,
      total_page: "",
      index_value: 30,
      license_status: "",
      popUp: false,
      select_user: [],
      update: {
        new: "",
        confirm: "",
      },
      message: {
        text: "",
        status: "",
      },
      loading: false,
      is_reset_password: false,
    };
  },

  components: { SideNav, TopBar, PaginationViewVue },
  created() {
    this.getUserInfo();
  },
  methods: {
    updatePassword() {
      this.loading = true;
      if (this.update.new == this.update.confirm) {
        let payLoad = {
          new_password: this.update.new,
          confirm_password: this.update.confirm,
        };
        axios
          .patch(
            `${process.env.VUE_APP_API}/api/admin/users/${this.select_user.id}/reset-password/`,
            payLoad
          )
          .then((response) => {
            console.log(response);
            this.message.text = "Password reset successfully";
            this.message.status = 200;
            this.update.new = "";
            this.update.confirm = "";
            setTimeout(() => {
              this.loading = false;
              this.message.text = "";
              this.message.status = "";
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
            this.message.text = "Try again ";
            this.message.status = 400;
            setTimeout(() => {
              this.loading = false;
              this.message.text = "";
              this.message.status = "";
            }, 3000);
          });
      } else {
        console.log("Password Not Same");

        this.message.text = "New password and confirm password are not same";
        this.message.status = 400;
        setTimeout(() => {
          this.loading = false;
          this.message.text = "";
          this.message.status = "";
        }, 3000);
      }
    },
    getUserInfo(pageNumber = 1) {
      console.log(pageNumber);
      this.current_page = pageNumber;
      this.index_value = (pageNumber - 1) * this.count_per_page;
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-users-all/?Key=0d27198fa776de1bec62f55ccb68ae199b477ce5&page=${this.current_page}&status=${this.license_status}`
        )
        .then((response) => {
          console.log(response);

          this.user_info = response.data.results;
          console.log(response.data.count, "user count: ");
          this.total_page = Math.ceil(
            response.data.count / this.count_per_page
          );
        });
    },
  },
};
</script>

<style></style>
