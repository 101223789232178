<template>
  <div class="flex overflow h-screen w-full overflow-y-hidden roboto-light">
    <SideNav />

    <div class="md:flex-1 md:max-w-full mx-auto bg-[#F1F8FE]">
      <TopBar />
      <div class="h-screen overflow-y-scroll p-5">
        <div class="flex-col flex md:flex-row p-4 w-full gap-x-2 hidden">
          <button
            class="text-white bg-[#4377F7] rounded-md p-2 text-lg font-bold w-[70%]"
          >
            For Individual and Small Teams
          </button>

          <button
            class="text-white bg-black rounded-md p-2 text-lg font-bold w-[30%]"
          >
            For BPO & Enterprise
          </button>
        </div>
        <div class="md:flex p-5 md:gap-x-3">
          <!-- Card 1 -->
          <div class="bg-white rounded-lg shadow-lg w-1/3">
            <div class="bg-black text-white text-center py-4">
              <h2 class="text-lg">Personal</h2>
              <p class="h-[40px]">Trail Plan</p>
            </div>
            <div class="bg-[#232323] text-white text-center py-8 h-40">
              <p class="text-4xl font-bold">$0</p>
              <p class="">Try Free For 30 Days</p>
              <p class="h-[40px]"></p>
            </div>
            <ul class="divide-y divide-gray-200 p-5">
              <li class="py-2 text-center">Environment Noise Suppression</li>
              <li class="py-2 text-center">Real-time Echo Cancellation</li>
              <li class="py-2 text-center">Digital Signal Processing</li>
              <li class="py-2 text-center">Adaptive Audio Filtering</li>
              <li class="py-2 text-center">
                Unlimited Usage - FREE for 30 Days
              </li>
              <li class="py-2 text-center">Per User</li>
              <li class="py-2 text-center">-</li>
              <li class="py-2 text-center">-</li>
              <li class="py-2 text-center">-</li>
              <li class="py-2 text-center">
                Recommended for Personal Home Users,Streamers!
              </li>
            </ul>
            <div
              class="text-center py-8 bg-gradient-to-b from-slate-100 to-gray-100 shadow-xl"
            >
              <button
                class="bg-[#2323B7] text-white py-3 md:py-5 md:text-2xl px-4 rounded w-[80%]"
              >
                Free!
              </button>
            </div>
          </div>

          <!-- Card 2 -->
          <div class="bg-white shadow-lg w-1/3">
            <div class="bg-black text-white text-center py-4">
              <h2 class="text-lg">Professional</h2>
              <div class="flex gap-2 justify-center py-2">
                Half Yearly
                <label class="switch">
                  <input
                    @click="togglePayment"
                    type="checkbox"
                    :checked="is_toggle"
                  />
                  <span class="slider round"></span>
                </label>
                Annually
              </div>
            </div>
            <div
              class="text-center py-8 leading-10 bg-[#232323] text-white h-40"
            >
              <div v-if="is_toggle" class="text-4xl font-bold">
                <!-- <span class="line-through text-red-700"
                  >${{
                    Math.round(professional_year_plan[0]?.marked_price)
                  }}</span
                > -->
                ${{ professional_year_plan[0]?.selling_price }}
              </div>
              <p v-else class="text-4xl font-bold">
                ${{ professional_half_year_plan[0]?.selling_price }}
              </p>

              <p class="">
                Per {{ is_toggle ? "year" : "6 months" }} / Per license
              </p>
              <!-- <p>
                Get
                {{
                  Math.round(professional_year_plan[0]?.discount_percentage)
                }}% Discount on Annual Billing!
              </p> -->
            </div>
            <ul class="divide-y divide-gray-200 p-5">
              <li class="py-2 text-center">Environment Noise Suppression</li>
              <li class="py-2 text-center">Real-time Echo Cancellation</li>
              <li class="py-2 text-center">Digital Signal Processing</li>
              <li class="py-2 text-center">Adaptive Audio Filtering</li>
              <li class="py-2 text-center">Unlimited Usage</li>
              <li class="py-2 text-center">Per User</li>
              <li class="py-2 text-center">-</li>
              <li class="py-2 text-center">-</li>
              <li class="py-2 text-center">-</li>
              <li class="py-2 text-center">
                Recommended for Professionals, Small Teams, Home-Office
              </li>
            </ul>
            <div
              class="text-center py-8 bg-gradient-to-b from-slate-100 to-gray-100 shadow-xl"
            >
              <button
                @click="paymentGateway"
                class="bg-[#2323B7] text-white py-3 md:py-5 md:text-2xl px-4 rounded w-[80%]"
              >
                Check Out!
              </button>
            </div>
          </div>

          <!-- Card 3 -->
          <div class="bg-white shadow-lg w-1/3">
            <div class="bg-black text-white text-center py-4">
              <h2 class="text-lg">Business</h2>
              <p class="h-[40px]"></p>
            </div>
            <div
              class="bg-[#232323] text-white text-center py-8 h-40 space-y-3"
            >
              <p class="text-3xl">Contact Us</p>
              <p class="text-3xl">For Bulk Licenses</p>
              <p>(Minimum 25 Users & above)</p>
            </div>
            <ul class="divide-y divide-gray-200 p-5">
              <li class="py-2 text-center">Environment Noise Suppression</li>
              <li class="py-2 text-center">Real-time Echo Cancellation</li>
              <li class="py-2 text-center">Digital Signal Processing</li>
              <li class="py-2 text-center">Adaptive Audio Filtering</li>
              <li class="py-2 text-center">Unlimited Usage</li>
              <li class="py-2 text-center">Per User</li>
              <li class="py-2 text-center">Multi User Organization</li>
              <li class="py-2 text-center">Admin Dashboard</li>
              <li class="py-2 text-center">Flexible Payment Option</li>
              <li class="py-2 text-center">
                Recommended for Large Enterprises, Call-Centres/BPO.
              </li>
            </ul>
            <div
              class="text-center py-8 bg-gradient-to-b from-slate-100 to-gray-100 shadow-xl"
            >
              <button
                class="bg-[#2323B7] text-white py-3 md:py-5 md:text-2xl px-4 rounded w-[80%]"
              >
                Contact!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "./TopBar.vue";
import SideNav from "./SideNav.vue";
import axios from "axios";
export default {
  data() {
    return {
      is_toggle: true,
      professional_month_plan: [],
      professional_year_plan: [],
      professional_half_year_plan: [],
      selected_payment: "yearly",
    };
  },
  components: {
    TopBar,
    SideNav,
  },
  created() {
    this.professionalYearPlan();
    this.professionalMonthPlan();
    this.professionalHalfYearlyPlan();
  },
  methods: {
    togglePayment(el) {
      this.is_toggle = el.srcElement.checked;
      this.selected_payment = this.is_toggle ? "yearly" : "half yearly";
    },
    professionalYearPlan() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-subscriptions/?title=professional-yearly`
        )
        .then((response) => {
          this.professional_year_plan = response.data.results;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    professionalMonthPlan() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-subscriptions/?title=professional-monthly`
        )
        .then((response) => {
          this.professional_month_plan = response.data.results;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    professionalHalfYearlyPlan() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-subscriptions/?title=half-yearly`
        )
        .then((response) => {
          this.professional_half_year_plan = response.data.results;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    paymentGateway() {
      let plan_info = this.is_toggle
        ? this.professional_year_plan[0]?.id
        : this.professional_half_year_plan[0]?.id;
      sessionStorage.setItem("plan_info", plan_info);
      let slug = this.is_toggle
        ? this.professional_year_plan[0]?.title
        : this.professional_half_year_plan[0]?.title;
      this.$router.push({
        name: "ExpressCheckOut",
        params: { plan_type: `${slug}` },
      });
    },
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
